import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';

import AppContent from './components/AppContent';

import { Helmet } from 'react-helmet';
import ScriptTag from 'react-script-tag';

const App = () => {
	return (
		<>
			<AppContent />
		</>
	);
};

export default App;

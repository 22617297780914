import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../helpers/loader';

const AppContent = () => {
	const { user: currentUser, isLoggedIn: userLogged } = useSelector((state) => state.auth,);

	const DefaultLayout = React.lazy(() => import('../layout'));

	const Login = React.lazy(() => import('../components/login'));
	const Register = React.lazy(() => import('../components/register'));
	// const Page404 = React.lazy(() => import('./components/login'));
	// const Page500 = React.lazy(() => import('./components/login'));

	const isLoggedIn = () => {
		if (!userLogged) {
			return <Redirect to='/login' />;
		}
	};

	useEffect(() => {
		if (window && document) {
			const script = document.createElement('script');
			const body = document.getElementsByTagName('body')[0];
			script.src = '/main.js';
			body.appendChild(script);
		}
	}, []);

	return (
		<>
			<Suspense fallback={<Loading />}>
				<BrowserRouter>
					<Switch>
						<Route exact path='/'>
							<Redirect to='/home' />
						</Route>
						<Route
							exact
							path='/login'
							name='Login Page'
							render={(props) => <Login {...props} />}
						/>
						<Route
							exact
							path='/register'
							name='Register'
							render={(props) => <Register {...props} />}
						/>
						{isLoggedIn()}
						<Route
							path='/'
							name='home'
							render={(props) => <DefaultLayout {...props} />}
						/>
					</Switch>
				</BrowserRouter>
			</Suspense>
		</>
	);
};

export default React.memo(AppContent);
